import React, {createRef} from 'react';
import Layout from 'components/common/layout/layout';
import Contacto from "components/common/contacto/Contacto";
import BlockContent from "@sanity/block-content-to-react";
import Reviews from "components/common/reviews/reviews";
import Contact from "components/common/form";
import Breadcrumb from "../components/common/breadcrumbs/Breadcrumb";
import theme from "styles/style";
import {ServiciosSlider} from "../components/servicios/serviciosSlider";
import Slider from "../components/common/Slider/Slider";
import {Pasos} from "../components/servicios/pasos";
import Servicio from "components/servicios/servicio";
import Hero from "../components/common/cover/hero";
import SEO from "../components/common/index/SEO";
import {graphql} from "gatsby";
import {CameraBG} from 'components/home/Content/occss-home';
import ServiceMarkupSchema from "../components/common/SEO/ServiceMarkupSchema";


export default function Service({pageContext: {service, id, eq, categoriesIdList}, data}) {

    const start = createRef(null);
    const end = createRef(null);
    let block_1 = null;
    const block_2 = createRef(null);
    const block_3 = createRef(null);
    let has_subservices = false;


    const adjust = {
        start: {left: 10, color: `${theme.orange}`, size: 400, animation: false, center: false},
        block_1: {left: 65, color: `${theme.blue}`, size: 150, animation: true, center: false},
        block_2: {left: 15, color: `${theme.blue}`, size: 300, animation: false, center: false},
        block_3: {left: 15, color: `${theme.green}`, size: 150, animation: false, center: true},
        block_4: {left: 0, color: `${theme.green}`, size: 300, animation: false, center: false},
        block_5: {left: 0, color: `${theme.green}`, size: 200, animation: true, center: true},
    };

    console.log("data", categoriesIdList, data);
    return (
        <>
            <SEO
                title={service.seo_title}
                description={service.seo_description}
                robots='index, follow'
                canonical={`servicios/${service.slug.current}/`}
            />
            <ServiceMarkupSchema name={service.h1__title} slug={service.slug.current}
                                 image={service.mainImage.asset.gatsbyImageData.images.fallback.src}/>
            <Layout start={start} block_1={block_1} block_2={block_2} block_3={block_3} end={end} adjust={adjust}>
                <Breadcrumb to={"/servicios/"}> Servicios </Breadcrumb>
                <Hero to={`#${service.slug.current}`} src={data.sanityHeroServices.codeVideo__landscape}
                      vsrc={data.sanityHeroServices.codeVideo__portrait} title={"Servicios"}/>

                <div className={"container padding-xl"} id={service.slug.current} ref={start}>
                    <Servicio
                        title={service.h1__title}
                        to={"#contactar"}
                        text={"CONTÁCTANOS"}
                        color={theme.orange}
                        isDown={true}
                        content={service._rawText}
                    />
                </div>

                {/*<CameraBG className={"padding-xxl"}>*/}
                {/*    <div className={"container"} ref={block_2}>*/}
                {/*        {service.h2__title2 &&*/}
                {/*        <>*/}
                {/*        <h2 className={"h1 putDot--blue"}>*/}
                {/*            {service.h2__title2}*/}
                {/*        </h2>*/}
                {/*        <Pasos />*/}
                {/*        </>*/}
                {/*        }*/}
                {/*    </div>*/}
                {/*</CameraBG>*/}
                {data.allSanityPortfolio.edges.length > 0 &&
                    <div className={"container"} ref={block_2}>
                        <h2 className={"h1 putDot--blue h1--column"}>
                            {service.h2__title2}
                        </h2>

                    </div>
                }
                {data.allSanityPortfolio.edges.length > 0 && <Slider works={data.allSanityPortfolio.edges}/>}
                <div className={"container padding-xl"}>
                    <h2 className={"h1 text-center putDot"} ref={block_3}>
                        PODEMOS AYUDARTE A RECORDAR TUS MOMENTOS
                    </h2>
                    <div className={"grid-x2"}>
                        <div><Reviews/></div>
                        <div><Contact/></div>
                    </div>
                    <div className={"container"} id={"contactar"} ref={end}>
                        <Contacto/>
                    </div>
                </div>
            </Layout>
        </>
    )
}
export const query = () => {
    graphql`
        query IsolatedServiceVideoQuery( $eq: String = "bodas", $categoriesIdList: [String] = ["-cbb7a3a0-5cb1-5a25-aeb1-116f872f40d6"]) {
            sanityHeroServices(services: {slug: {current: {eq: $eq }}}) {
                codeVideo__portrait
                codeVideo__landscape
            }
            allSanityPortfolio(filter: {categories: {elemMatch: {id: {in: $categoriesIdList}}}}, sort: {fields: _updatedAt, order: DESC}) {
                edges {
                    node {
                        codeVideo
                        title
                        mainImage {
                            _key
                            _type
                            asset {
                                _id
                                gatsbyImageData
                            }
                        }
                        publishedAt
                        slug {
                            current
                        }
                        categories {
                            title
                        }
                        services {
                            h1__title
                        }
                    }
                }
            }
        }
    `
};
